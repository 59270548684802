/**
 * @module hooks/useObtenerDatos
 */

import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import * as storage from '../storage';

/**
 * Hook alrededor de `useQuery` de `react-query` para simplificar su uso. Usar preferentemente
 * para peticiones que no signifiquen una modificación de los datos almacenados en la caché
 *
 * @param {object} params
 * @param {Array} params.nombre - la llave para identificar los datos en el caché interno creado
 * por `react-query`
 * @param {object} params.parametros - objeto con los parametros pasados a llamadaAPI
 * @param {Function} params.llamadaAPI - función que se ejecutará para hacer la petición `GET` al
 * backend, puede ser alguna de las funciones en el directorio `api` o una función anónima
 * envolviendo una llamada con `axios`
 * @param {Function} params.onSuccess - función que se ejecuta en caso de una petición exitosa
 * @param {Function} params.onError - función que se ejecuta en caso de una petición fallida
 * @param {boolean} params.condicion - bandera que indica si `useQuery` debe ejecutarse o no
 * @param {boolean} params.cache - si almacenar la respuesta en caché interno o no
 * @param {boolean} params.token - enviar el token de usuario en la request o no
 * @param {boolean} params.gcTime - tiempo en minutos que será borrado después de estar inactivo
 * @returns {object}
 */
function useObtenerDatos({
  nombre = null,
  parametros = null,
  llamadaAPI,
  onSuccess = null,
  onError = null,
  condicion = null,
  nofresh = false,
  usaToken = true,
  select = null,
  defaultValue = null,
  token = null,
  caller = null,
  refetch = null,
  gcTime = null // minutos
}) {
  if (!llamadaAPI) {
    throw new Error('Falta llamadaAPI');
  }

  if (nombre && typeof nombre !== 'string') {
    throw new Error('nombre debe ser un string');
  }

  if (parametros && typeof parametros !== 'object') {
    throw new Error('parametros debe ser un objeto');
  }

  let queryKey = nombre;
  if (!nombre) {
    queryKey = `qk_${Math.random().toString(36).slice(2, 25)}`;
  }

  let objQueryKey = null;
  if (parametros || usaToken) {
    objQueryKey = {};

    if (parametros) {
      objQueryKey = { ...objQueryKey, ...parametros };
    }
  }

  queryKey = [queryKey, objQueryKey];

  let usarCache = true;
  if (process.env.REACT_APP_USE_CACHE !== undefined && process.env.REACT_APP_USE_CACHE === 'false') {
    usarCache = false;
  }

  if (nofresh) {
    usarCache = false;
  }

  // TODO: revisar opciones:
  // - initialData

  if (!token && usaToken) {
    token = storage.token.obtener();
  }

  const enabled =
    (condicion !== null && condicion !== undefined ? condicion : true) &&
    (usaToken ? !!token : true) &&
    (parametros !== null && parametros !== undefined ? !!parametros : true);

  const { data, isFetching, isLoading, error, isError, status, fetchStatus, ...rest } = useQuery({
    queryKey,
    queryFn: () => llamadaAPI({ ...parametros }),
    ...(select && {
      select: (data) => select(data)
    }),
    ...(!usarCache && { staleTime: 0 }),
    ...(gcTime !== null
      ? { gcTime: gcTime * 60 * 1000 }
      : {
          gcTime: 1000 * 60 * 15
        }),
    // ...(refetch && { refetchInterval: refetch }),
    enabled
  });

  const [cargando, setCargando] = useState(true);

  useEffect(() => {
    setCargando(isFetching || isLoading);
  }, [isFetching, isLoading]);

  return {
    [nombre]:
      !cargando && data === undefined ? (nombre in ['token', 'pwdlsstkn'] ? null : defaultValue) : data,
    cargando,
    error: isError && error && (error.code === 'ERR_CANCELED' ? error : { ...error.response.data }),
    ...rest
  };
}

export default useObtenerDatos;
