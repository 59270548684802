import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMenu, useToken } from './app/hooks';

import { Container } from '@mui/material';
import * as state from './app/state';

import ReactGA from 'react-ga';
import Rutas from './Rutas';
import { deviceHash } from './utils';
const TRACKING_ID = 'G-DXZY5PP32P';
ReactGA.initialize(TRACKING_ID);

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useToken();

  const hashGuardado = useSelector(state.dataUsuario.deviceHashGuardado);

  useEffect(() => {
    if (!hashGuardado) {
      deviceHash().then((hash) => {
        dispatch(state.dataUsuario.guardarDeviceHash(hash));
      });
    }
  }, [dispatch, hashGuardado]);

  const menu = useMenu(token);

  const servicioActivo = useSelector(state.config.servicioActivo);
  const rutaInicial = useSelector(state.config.rutaInicial);

  useEffect(() => {
    if (
      token.estado === 'ok' &&
      !['', '/'].includes(location.pathname) &&
      !location.pathname.includes('/autenticacion')
    ) {
      dispatch(state.config.setServicioActivo(location.pathname.split('/')[2]));
    }
  }, [dispatch, location, token]);

  useEffect(() => {
    if (!!menu && !rutaInicial && servicioActivo && !location.pathname.includes('/autenticacion')) {
      dispatch(state.config.setRutaInicial(menu.find((m) => m.ruta === servicioActivo).hijos[0].ruta));
    }
  }, [dispatch, menu, rutaInicial, servicioActivo, location]);

  useEffect(() => {
    if (token.estado === 'ok' && !token.token && !location.pathname.includes('/autenticacion')) {
      navigate('/autenticacion/ingresar', { replace: true });
    }
  }, [token, location, navigate]);

  return (
    <Container maxWidth='100vw' disableGutters sx={{ minHeight: '100vh', height: '100vh' }}>
      <Rutas menu={menu} />
    </Container>
  );
}

export default App;
