import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import * as storage from '../storage';

function useToken() {
  const location = useLocation();

  const [token, setToken] = useState(null);
  const [estado, setEstado] = useState('procesando');

  useEffect(() => {
    if (location.pathname.includes('/autenticacion')) {
      storage.token.remover();
    }
    setToken(storage.token.obtener());
    setEstado('ok');
  }, [location]);

  const guardarTokenEnLocalStorage = () => {
    setToken(storage.token.obtener());
  };

  useEffect(() => {
    window.addEventListener('storage', guardarTokenEnLocalStorage);

    return function cleanup() {
      window.removeEventListener('storage', guardarTokenEnLocalStorage);
    };
  }, []);

  return { token, estado };
}

export default useToken;
