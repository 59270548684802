import { useCallback, useEffect, useState } from 'react';

import useObtenerDatos from './useObtenerDatos';

import * as api from '../api';

function useMenu({ token = null, estado = null } = {}) {
  const { servicios } = useObtenerDatos({
    nombre: 'servicios',
    llamadaAPI: api.interno.obtenerServicios,
    token: token,
    condicion: estado === 'ok'
  });

  const { menu } = useObtenerDatos({
    nombre: 'menu',
    llamadaAPI: api.interno.obtenerMenuUsuario,
    token: token,
    condicion: estado === 'ok'
  });

  const rutasServicio = useCallback((rutas, servicio, rutaPadre = null) => {
    if (!rutas) return [];

    let rs = [];

    for (const ruta of rutas) {
      rs.push({
        ruta: (rutaPadre || servicio) + '/' + ruta.ruta,
        nombre: ruta.nombre,
        codigo: ruta.codigo,
        icono: ruta.icono,
        componente: ruta.componente_react,
        hijos: rutasServicio(
          ruta.hijos?.filter((h) => !!h.componente_react).sort((h1, h2) => h1.orden - h2.orden),
          servicio,
          (rutaPadre || servicio) + '/' + ruta.ruta
        )
      });
    }

    return rs;
  }, []);

  const [menuRutas, setMenuRutas] = useState(null);

  useEffect(() => {
    if (!!menu && !!servicios) {
      let mr = [];
      for (const [servicio, rutas] of Object.entries(menu)) {
        mr.push({
          ruta: servicio,
          nombre: servicios.find((s) => s.slug === servicio).nombre,
          codigo: null,
          icono: null,
          componente: null,
          hijos: rutasServicio(
            rutas.filter((h) => !!h.componente_react).sort((h1, h2) => h1.orden - h2.orden),
            '/servicios/' + servicio
          )
        });
      }
      setMenuRutas(mr);
    }
  }, [menu, rutasServicio, servicios]);

  return menuRutas;
}

export default useMenu;
