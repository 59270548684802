import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Typography,
  Button,
  IconButton,
  MenuItem,
  Menu,
  useTheme,
  useMediaQuery,
  TablePagination,
  ButtonGroup,
  Tooltip,
  styled
} from '@mui/material';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton
} from '@mui/x-data-grid';
import {
  ContactPageOutlined,
  MoreHorizOutlined,
  ReportOutlined,
  ReportProblemOutlined
} from '@mui/icons-material';

/**
 * @module app/components/servicios/rv/resultados/TablaResultados
 */

function TablaResultados({
  columnas,
  filas,
  titulo,
  children,
  idContador,
  filaRedireccion,
  opciones,
  contextoRedireccion,
  toolbar = true,
  paginacion = true
}) {
  const [menuPos, setMenuPos] = useState(null);

  const theme = useTheme();
  const pantallaXL = useMediaQuery(theme.breakpoints.up('xl'));

  const [paginationModel, setPaginationModel] = useState({ pageSize: 25, page: 0 });

  /*
   * Nota: La estructura "comunas" y "filas" es la que se habló en reunión; no es la de MUI.
   */
  const contador = useMemo(() => {
    let _contador = { medio: 0, alto: 0, rangos: null };

    if (idContador) {
      columnas.some((col) => {
        if (col.id === idContador) {
          _contador.rangos = col.params[0].rango;
          return true;
        }
        return false;
      });

      if (_contador.rangos !== null) {
        // este arreglo no es muy elegante pero funciona por ahora
        filas.forEach((fila) => {
          // Esto no debería evaluar rangos específicos, debería ser dinámico. Pero por ahora está bien
          if (fila[idContador] >= _contador.rangos[1][0] && fila[idContador] <= _contador.rangos[1][1])
            _contador.medio++;
          if (fila[idContador] >= _contador.rangos[2][0] && fila[idContador] <= _contador.rangos[2][1])
            _contador.alto++;
        });
      }
    }
    return _contador;
  }, [idContador, columnas, filas]);

  const columnasProcesadas = useMemo(() => {
    return columnas.map((col) => {
      const hexComoTexto = {
        0: 'Z',
        1: 'O',
        2: 'T',
        3: 'H',
        4: 'R',
        5: 'V',
        6: 'S',
        7: 'N',
        8: 'G',
        9: 'I'
      };
      // ^ Sé que esto es extremadamente poco elegante, pero MUI no me permite usar clases con numeros (como por ejemplo, hexadecimal),
      // y necesito poder asignar clases unicas por color. Cambiaré esto por algo mejor más adelante.
      let renderVariacion = undefined;
      // let claseCelda = filaRedireccion ? 'selectable' : undefined;
      let claseCelda = undefined;

      if (col.params) {
        switch (col.params[0].tipo) {
          case 'marcado':
            renderVariacion = (props, tipo = col.tipo) => {
              const { value } = props;
              let colour = '#65B78C';
              col.params[0].rango.some((_rango) => {
                if (value >= _rango[0] && value <= _rango[1]) {
                  colour = _rango[2];
                  return true;
                }
                return false;
              });

              return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                  {tipo === 'porcentaje' ? `${parseInt(value).toLocaleString('es-CL')}%` : value}
                  <Marca color={colour} />
                </div>
              );
            };
            break;
          case 'coloreado':
            col.params[0].rango.forEach((_rango) => {
              _rango[3] = _rango[2].substring(1).replace(/[0123456789]/g, (c) => hexComoTexto[c]);
              cssDinamico.celdas['& .' + col.id + '_' + _rango[3]] = {
                backgroundColor: _rango[2]
              };
            });
            claseCelda = (params) => {
              let colour = 'ffffff';
              col.params[0].rango.some((_rango) => {
                if (params.value >= _rango[0] && params.value <= _rango[1]) {
                  colour = _rango[3];
                  return true;
                }
                return false;
              });
              return col.id + '_' + colour;
            };
            break;
          default:
            break;
        }
      }
      let formatoValor = undefined;
      switch (col.tipo) {
        case 'segundos':
          formatoValor = (valor) => {
            valor = valor ?? 0;

            const horas = Math.floor(valor / 3600);
            const minutos = Math.floor((valor % 3600) / 60);

            return `${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`;
          };
          break;
        case 'texto':
          formatoValor = (valor) => valor;
          break;
        case 'porcentaje':
          formatoValor = (valor) => parseInt(Math.round(valor ?? 0.0)) + '%';
          break;
        case 'int':
        case undefined:
          formatoValor = (valor) => parseFloat(Math.round(valor ?? 0.0)).toLocaleString('es-CL');
          break;
        case 'float':
          formatoValor = (valor) => parseFloat(valor ?? '0').toLocaleString('es-CL');
          break;
        case 'acciones':
          renderVariacion = (props) => {
            return (
              <Acciones
                props={props}
                botones={col.botones}
                filaRedireccion={filaRedireccion}
                contextoRedireccion={contextoRedireccion}
              />
            );
          };
          break;
        default:
          formatoValor = (params) => params.value;
          break;
      }

      return {
        field: col.id,
        headerName: col.label,
        width: col.minLargo,
        headerClassName: col.concepto ? 'concepto' : 'cabecera',
        renderCell: renderVariacion,
        type: ['int', 'float'].includes(col.tipo) ? 'number' : 'string',
        valueFormatter: formatoValor,
        cellClassName: claseCelda,
        align:
          !col.tipo || ['int', 'porcentaje', 'float', 'segundos'].includes(col.tipo)
            ? 'right'
            : col.tipo === 'acciones'
            ? 'center'
            : 'left',
        disableColumnMenu: col.tipo === 'acciones',
        sortable: col.tipo !== 'acciones',
        filterable: col.tipo !== 'acciones'
      };
    });
  }, [columnas, filaRedireccion, contextoRedireccion]);

  const onMenuCerrar = () => setMenuPos(null);
  const onDescarga = () => {
    console.log('TODO: Descarga');
    setMenuPos(null);
  };
  const onMenuAbrir = (e) => setMenuPos(e.currentTarget);

  const getRowClassName = (params) => {
    let rowClass = 'row-';

    if (!params.row.grupo) {
      rowClass += 'default';
    } else {
      rowClass += params.row.grupo;
    }

    return rowClass;
  };

  return (
    <>
      <Box sx={css.cardHeader}>
        {titulo && (
          <Typography sx={css.h2} className='cardHeader'>
            {' '}
            {titulo}{' '}
          </Typography>
        )}
        {opciones && (
          <>
            <IconButton aria-label='opciones' onClick={onMenuAbrir}>
              <MoreHorizOutlined />
            </IconButton>
            <Menu
              anchorEl={menuPos}
              open={!!menuPos}
              onClose={onMenuCerrar}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <MenuItem onClick={onDescarga}>Descargar a Excel</MenuItem>
            </Menu>
          </>
        )}
      </Box>
      {children && <Typography sx={{ ...css.sectionDesc, mb: 2 }}> {children} </Typography>}
      <Box sx={{ ...css.contenedorTabla }}>
        <ThallusDataGrid
          columns={columnasProcesadas}
          rows={filas}
          pageSize={paginationModel.pageSize}
          sx={{
            border: 'none',
            paddingX: 1,
            ...cssDinamico.celdas,
            ...cssDinamico.tableHeader
            // '& .Mui' #202521
          }}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          getRowClassName={getRowClassName}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          density='compact'
          autoHeight
          disableRowSelectionOnClick
          slots={{ toolbar: ToolBar }}
          slotProps={{
            toolbar: {
              paginacion: {
                count: filas.length,
                rowsPerPage: paginationModel.pageSize,
                page: paginationModel.page,
                onPageChange: (event, page) => setPaginationModel({ ...paginationModel, page }),
                onRowsPerPageChange: ({ target }) =>
                  setPaginationModel({ ...paginationModel, pageSize: target.value }),
                hide: !paginacion || filas.length <= 10
              },
              columnas: { hide: !toolbar && pantallaXL },
              filtros: { hide: !toolbar || filas.length <= 10 },
              contador: contador
            },
            columnsManagement: {
              getTogglableColumns: (columns) =>
                columns.filter((col) => !['acciones'].includes(col.field)).map((col) => col.field)
            }
          }}
          hideFooter={!paginacion || paginationModel.pageSize < 25}
        />
      </Box>
    </>
  );
}

function Rangos({ contador }) {
  return (
    !!contador?.rangos && (
      <>
        <Button variant='outlined' startIcon={<ReportOutlined />} sx={{ cursor: 'auto', ...css.varMedia }}>
          {contador.medio}
        </Button>
        <Button
          variant='outlined'
          startIcon={<ReportProblemOutlined />}
          sx={{ cursor: 'auto', ...css.varAlta }}
        >
          {contador.alto}
        </Button>
      </>
    )
  );
}

function ToolBar({ columnas, filtros, paginacion, contador }) {
  return (
    <GridToolbarContainer>
      <Rangos contador={contador} />
      {!columnas.hide && <GridToolbarColumnsButton slotProps={{ button: { sx: { color: '#202521' } } }} />}
      {!filtros.hide && <GridToolbarFilterButton slotProps={{ button: { sx: { color: '#202521' } } }} />}
      {/* <GridToolbarExport slotProps={{ button: { sx: { color: '#202521' } } }}  /> */}
      {!paginacion.hide && (
        <TablePagination
          sx={{ ml: 'auto' }}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component='div'
          count={paginacion.count}
          rowsPerPage={paginacion.rowsPerPage}
          page={paginacion.page}
          onPageChange={paginacion.onPageChange}
          onRowsPerPageChange={paginacion.onRowsPerPageChange}
        />
      )}
    </GridToolbarContainer>
  );
}

let cssDinamico = {
  celdas: {
    '& .65B78C': {
      backgroundColor: '#FF0000',
      color: 'red'
    },
    '& .selectable': {
      cursor: 'pointer'
    }
  },
  tableHeader: {
    '& .concepto': {
      backgroundColor: '#c7c1bc'
    }
  }
};

const css = {
  h2: {
    fontSize: { xs: 17, lg: 20 },
    fontWeight: 600,
    margin: 0,
    marginBottom: 1
  },
  sectionDesc: {
    fontSize: { xs: 15, lg: 17 },
    fontWeight: 'light'
  },
  contenedorTabla: {
    position: 'relative',
    width: '100%',
    // '&::before': {
    //   content: "''",
    //   position: 'absolute',
    //   top: 0,
    //   left: 0,
    //   right: 0,
    //   height: 45,
    //   boxShadow: '0px 2px 11px rgba(69, 81, 76, 0.14)',
    //   borderRadius: '6px',
    //   backgroundColor: '#fff'
    // },
    '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none !important'
    },
    '& .MuiDataGrid-columnHeaders > div:first-of-type': {
      border: 0,
      fontWeight: 700,
      height: '45px',
      maxHeight: '45px !important',
      backgroundColor: 'rgba(69, 81, 76, 0.14)',
      alignItems: 'center'
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      overflow: 'initial',
      whiteSpace: 'initial',
      lineHeight: '16px'
    },
    '& .MuiDataGrid-cell': {
      display: 'flex',
      alignItems: 'center'
    }
    // '& .MuiDataGrid-virtualScroller': {
    //   marginTop: '45px !important'
    // }
  },
  cardHeader: {
    textAlign: 'right',
    display: 'block',
    '& .cardHeader': {
      float: 'left'
    },
    '&::after': {
      content: "''",
      clear: 'both',
      display: 'block'
    }
  },
  varMedia: {
    backgroundColor: '#fffcf0',
    color: '#D19233',
    border: 'none',
    borderRadius: 20,
    '&:hover': { backgroundColor: '#fffcf0', border: 'none' }
  },
  varAlta: {
    backgroundColor: '#fff0f0',
    color: '#D94B4B',
    border: 'none',
    borderRadius: 20,
    '&:hover': { backgroundColor: '#fff0f0', border: 'none' }
  },
  filtro: {
    color: '#202521'
  },
  // CSS de las Celdas
  marca: {
    height: 6,
    width: 6,
    backgroundColor: '#65B78C',
    borderRadius: '50%',
    marginLeft: 6
  },
  busqueda: {
    '& fieldset': {
      borderRadius: '32px'
    },
    maxWidth: '200px'
  },
  busquedaInput: {
    fontSize: '14px'
  }
};

const ThallusDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .row-default': { backgroundColor: '#FFF' },
  '& .row-cargo': { backgroundColor: '#FFF' },
  '& .row-tipo_cargo': { backgroundColor: '#E4E6E5' },
  '& .row-total': { backgroundColor: '#E4E6E5' }
}));

function Marca({ color }) {
  return <div style={{ ...css.marca, backgroundColor: color, outline: '3px solid ' + color + '38' }}></div>;
}

function Acciones({ props, botones, filaRedireccion, contextoRedireccion }) {
  const navigate = useNavigate();

  const btns = {
    ver: {
      props: {
        onClick: () => {
          if (!filaRedireccion) return;
          navigate(filaRedireccion, { state: { fila: props.row, contexto: contextoRedireccion } });
        }
      },
      icon: <ContactPageOutlined />,
      tooltip: 'Ver Anexo'
    }
  };

  return (
    <ButtonGroup variant='outlined' sx={{ border: 'none' }}>
      {botones.map((b) => (
        <Button
          key={b}
          name='btn-accion'
          sx={{
            color: '#202521',
            padding: 0,
            border: 'none',
            minWidth: '20px !important',
            ':hover': {
              backgroundColor: '#ccccd0',
              color: '#202521',
              border: 'none',
              transition: 'all .4s ease',
              webkitTransition: 'all .4s ease'
            }
          }}
          {...btns[b].props}
        >
          {btns[b].tooltip ? (
            <Tooltip placement='top' title={btns[b].tooltip}>
              {btns[b].icon}
            </Tooltip>
          ) : (
            btns[b].icon
          )}
        </Button>
      ))}
    </ButtonGroup>
  );
}

export default TablaResultados;
